import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import storage from "../../../Utils/storage";
import Details from "../../Molecules/Plan/PlanDetails";
import "./planContent.css";

export default function PlanContent() {
  const navigate = useNavigate();
  const { slug } = useParams();

  const PlanUuid = slug?.slice(slug.length - 36);

  const getPlanServicesDetail = useSelector(
    (state) => state.plan.getPlanServicesDetail
  );

  function handleBuy(e) {
    storage.setStorage("PurchasePlanUuid", PlanUuid);
    if (storage.getStorage("token") !== null) {
      navigate(`/cart-checkout-plan`, {
        state: {
          uuid: PlanUuid,
        },
      });
    } else {
      navigate("/login", {
        state: "plan",
      });
    }
  }

  return (
    <div className="planContent">
      <div className="planContentButtons">
        {/* {buttonInfo.map((item, index) => (
          <button
            className={`planContentButton ${index === clickedButtonIndex ? "clicked" : ""
              }`}
            key={index}
            onClick={() => handleClick(index)}
          >
            {item}
          </button>
        ))} */}
      </div>
      <Details getPlanServicesDetail={getPlanServicesDetail} />
      <div className="PlanTermsAndConditionsButtonDiv">
        <button
          className="PlanTermsAndConditionsButton"
          onClick={() => {
            handleBuy();
          }}
        >
          {" "}
          BUY NOW
        </button>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import Back from "../../../assets/Network/networkBack.png";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import ClinicForm from "../../Organisms/NetworkForms/ClinicForm";
import DiagnosticForm from "../../Organisms/NetworkForms/DiagnosticForm";
import HospitalForm from "../../Organisms/NetworkForms/HospitalForm";
import ProfessionalServicesForm from "../../Organisms/NetworkForms/ProfessionalServicesForm";
import "./NetworkForm.css";

function NetworkForm() {
  const navigate = useNavigate();
  const [FormType, setFormType] = useState();

  const checkFormType = storage.getStorage("FormType")

  useEffect(() => {
    if (checkFormType === null) {
      storage.setStorage("Hospital");
      setFormType("Hospital");
    } else {
      setFormType(checkFormType);
    }
  }, [checkFormType]);

  if (FormType === null) {
    storage.setStorage("FormType", "Hospital");
  }

  const handleForm = (e) => {
    storage.setStorage("FormType", e.target.value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Title title="YUVA | Partner with us" />

      <ScrollToTop />

      {/* <Banner /> */}
      <div className="networkBody1">
        <div style={{ marginTop: "5vw" }} className="networkMainHeading">
          Partner with us to explore different avenues of growth and improve
          your patient engagement and care.
        </div>
      </div>

      <div className="joinNetworkBody">
        <div className="joinNetworkTitle">
          <img src={Back} onClick={handleBack} alt="Back icon" />
          <div>Partner with us</div>
        </div>
        <div className="joinNetworkFormBody">
          <div>
            <select
              name="form"
              className="NetworkPageFormDropdownField"
              value={FormType}
              onChange={handleForm}
            >
              <option value="na">Select </option>
              <option value="Hospital">Hospital </option>
              <option value="Professional_service">Professional service</option>
              <option value="Diagnostic">Diagnostic Center</option>
              <option value="Clinic">Clinic</option>
            </select>
          </div>
          {storage.getStorage("FormType") === "Hospital" && <HospitalForm />}
          {storage.getStorage("FormType") === "Professional_service" && (
            <ProfessionalServicesForm />
          )}
          {storage.getStorage("FormType") === "Clinic" && <ClinicForm />}
          {storage.getStorage("FormType") === "Diagnostic" && (
            <DiagnosticForm />
          )}
        </div>
      </div>
    </div>
  );
}

export default NetworkForm;

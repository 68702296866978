import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { getCallRequest } from "../../../redux/Action/Advertisement/GetCallRequestAction";
import "./BookAppointment.css";

function BookAppointment({ closeAppointment, nav }) {
  const [isClick, setIsClick] = useState(false);

  const getCallFormValidation = Yup.object().shape({
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Please Enter a Valid 10-digit Number")
      .required("Mobile Number is Required"),
    Name: Yup.string().required("Name is a Required Field"),
    city: Yup.string().required("City is a Required Field"),
  });

  const handleSubmit = (values) => {
    dispatch(getCallRequest(values));
    setIsClick(true);
  };

  const initialValues = useMemo(() => ({
    Name: "",
    mobileNumber: "",
    city: "",
  }), []);


  const formik = useFormik({
    initialValues,
    validationSchema: getCallFormValidation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });


  const dispatch = useDispatch();

  const getCall = useSelector((state) => state.advertisement.getCall);
  const viewAllCity = useSelector((state) => state.city.viewAllCity);

  useEffect(() => {
    if (
      getCall?.data?.data.status === 201 &&
      getCall?.data?.data.data.message !== ""
    ) {
      Swal.fire({
        text: getCall.data.data.data.message,
        icon: "success",
      }).then(() => {
        formik.resetForm({ values: initialValues });
        setIsClick(false);
        closeAppointment(false);
      });
      getCall.data.data.status = "";
    }
  }, [getCall, closeAppointment, formik, initialValues]);


  function handleAppointmentModal() {
    closeAppointment(false);
  }

  return (
    <div className={nav === true ? "bookAppointmentFormMain" : "bookAppointmentFormMain1"}>
      <div className="bookAppointmentFormMainUpper">
        <p className="bookAppointmentFormMainHeader">Request A Call From Health Adviser</p>
        <CloseIcon className="bookAppointmentCloseIcon" onClick={handleAppointmentModal} />
      </div>
      <div className="bookAppointmentForm">
        <form onSubmit={formik.handleSubmit}>
          <div className="bookAppointmentFormLabels">
            <label className="">Name</label>
            <input
              type="text"
              name="Name"
              placeholder="Type Your Name Here"
              className="bookAppointmentFormLabelsInput"
              onWheel={(event) => event.currentTarget.blur()}
              {...formik.getFieldProps("Name")}
            />
            {formik.touched.Name && formik.errors.Name && (
              <div style={{ color: "red" }}>{formik.errors.Name}</div>
            )}
          </div>
          <div className="bookAppointmentFormLabels">
            <label className="">Phone Number</label>
            <input
              type="number"
              name="mobileNumber"
              className="bookAppointmentFormLabelsInput"
              placeholder="Type Your Contact Number Here"
              {...formik.getFieldProps("mobileNumber")}
            />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <div style={{ color: "red" }}>{formik.errors.mobileNumber}</div>
            )}
          </div>
          <div className="bookAppointmentFormLabels">
            <label className="">Location</label>
            <select
              name="city"
              className="bookAppointmentFormLabelsInput"
              placeholder="Select Your City"
              {...formik.getFieldProps("city")}
            >
              <option value="" disabled>
                Select Your City
              </option>
              {viewAllCity?.data?.data?.data.data.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            {formik.touched.city && formik.errors.city && (
              <div style={{ color: "red" }}>{formik.errors.city}</div>
            )}
          </div>
          <p className="bookAppointmentFormTerms">
            *I authorize YuvaHealth representative to contact me. I understand
            that this will override the DND status on my mobile number
          </p>

          <div className="bookAppointmentFormButton" type="submit">
            <button type="submit" disabled={isClick}>
              BOOK APPOINTMENT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BookAppointment;

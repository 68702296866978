import RequestService from "../../RequestService";
import { api } from "../../../helpers/Advertisement/AdvertisementConstants";

class NetworkService {
  getCall(action) {
    const endPoint = `${api.getCall}`;
    return RequestService.postRequest(endPoint, {
      number: action.data.mobileNumber,
      city: action.data.city,
      name: action.data.Name,
    })
  }
}

export default new NetworkService();

import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import storage from "../../../Utils/storage";
import main from "../../../assets/PlanPage/main.png";
import BookAppointment from "../../Pages/ServicesOffered/BookAppointment";
import "./packageHero.css";

export default function PackageHero({
  relationsAllowed,
  PlanName,
  duration,
  price,
  finalCost,
}) {
  const navigate = useNavigate();
  const [openAppointment, setOpenAppointment] = useState(false);
  var nav = false;

  const search = useLocation().search;
  const PlanUuid = new URLSearchParams(search).get("id");

  function handleBuy(e) {
    storage.setStorage("PurchasePlanUuid", PlanUuid);
    if (storage.getStorage("token") !== null) {
      navigate(`/cart-checkout-plan`, {
        state: {
          uuid: PlanUuid,
        },
      });
    } else {
      navigate("/login", {
        state: "plan",
      });
    }
  }
  function handleAppointment() {
    setOpenAppointment(true);
  }

  function closeAppointment(boolean) {
    setOpenAppointment(boolean);
  }

  return (
    <div className="packageHero">
      {openAppointment ? (
        <BookAppointment closeAppointment={closeAppointment} nav={nav} />
      ) : (
        ""
      )}
      <div>
        <div style={{ display: "flex" , alignItems: 'flex-end'}}>
          <div className="packageHeroContent">
            {PlanName ? (
              <div className="packageHeroTitle">
                <span>{PlanName}: </span>
                Comprehensive Healthcare for Your Well-being
              </div>
            ) : (
              <div className="packageHeroTitle">
                We Are Providing Best & Affordable
                <br />
                <span>Diagnostic Services</span>
              </div>
            )}
            <div className="packageHeroDescription">
              Get a full body health checkup at the affordable cost . The
              package included, CBC , KFT, LFT , Thyroid profile, Vitamin
              profile ( D &12 ), Iron studies calcium & Urine Examination
            </div>
            <button
              className="packageHeroButton"
              onClick={PlanName ? handleBuy : handleAppointment}
            >
              {PlanName ? "Book Now" : "Book an Appointment"}
            </button>
          </div>
          <img src={main} alt="Doctors" />
        </div>

        {PlanName && (
          <div className="packageHeroFoot">
            <div className="packageHeroFootMember">
              <div className="packageHeroFootTitle">
                Member - {relationsAllowed.split(",").length}
              </div>
              <div>{relationsAllowed}</div>
            </div>
            <hr className="packageHeroFootLine" />
            <div className="packageHeroFootDuration">
              <div className="packageHeroFootTitle">Plan Period</div>
              <div>{duration}</div>
            </div>
            <hr className="packageHeroFootLine" />
            <div className="packageHeroFootPrice">
              {price === finalCost ? (
                <div className="packageHeroFootTitle">
                  <span className="packageHeroFinalPrice">₹ {finalCost}/-</span>
                </div>
              ) : (
                <div className="packageHeroPrice">
                  <span className="packageHeroFootTitle">₹ {finalCost}/-</span>
                  <span className="packageHeroDiscountPrice">₹ {price}/-</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

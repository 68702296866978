import { all } from "redux-saga/effects";
import rootAccountSaga from "./Account/rootAccountSaga";
import rootAddressSaga from './Address/rootAddressSaga';
import rootAdvertisementSaga from "./Advertisement/rootAdvertisementSaga";
import rootCartSaga from "./Cart/rootCartSaga";
import rootCheckEmailSaga from "./CheckEmail/rootCheckEmailSaga";
import rootContactUsSaga from "./ContactUsPage/rootContactUsSaga";
import rootElasticSearchSaga from "./ElasticSearch/rootElasticSearchSaga";
import rootErmSaga from "./ERM/rootErmSaga";
import rootHRASaga from "./HealthRiskAssessment/rootHRASaga";
import rootLandingSaga from "./Landing/rootLandingSaga";
import rootNetworkSaga from "./Network/rootNetworkSaga";
import rootOrderSaga from "./Order/rootOrderSaga";
import rootOTPSaga from "./OTP/rootOTPSaga";
import rootPackagesSaga from "./Packages/rootPackagesSaga";
import { watchgetSelectedPlanSaga } from "./Plan/GetSelectedPlanSaga";
import rootPlanSaga from "./Plan/rootPlanSaga";
import rootProfileLockSaga from "./ProfileLock/rootProfileLockSaga";
import rootTellAboutYourselfSaga from "./TellAboutYourselfPage/rootTellAboutYourselfSaga";
import rootUserSaga from "./User/rootUserSaga";
import rootMentalWellnessSaga from "./MentalWellness/rootMentalWellnessSaga";
import rootCorporateSignUpSaga from "./CorporateSignUp/rootCorporateSignUpSaga";
import rootUserBannerSaga from "./Banner/rootUserBannerSaga";
import rootdeviceSaga from "./Token/rootdeviceSaga";
import rootRefreshTokenSaga from "./RefreshToken/rootRefreshTokenSaga";
import rootEcomUserSaga from "./EcomUser/rootEcomUserSaga";
import rootCitySaga from "./City/rootCitySaga";

function* rootSaga() {
  yield all([
    rootAccountSaga(),
    rootAddressSaga(),
    rootCartSaga(),
    rootCheckEmailSaga(),
    rootContactUsSaga(),
    rootElasticSearchSaga(),
    rootHRASaga(),
    rootLandingSaga(),
    rootNetworkSaga(),
    rootOrderSaga(),
    rootOTPSaga(),
    rootPackagesSaga(),
    rootPlanSaga(),
    rootProfileLockSaga(),
    rootTellAboutYourselfSaga(),
    rootUserSaga(),
    watchgetSelectedPlanSaga(),
    rootAdvertisementSaga(),
    rootErmSaga(),
    rootMentalWellnessSaga(),
    rootCorporateSignUpSaga(),
    rootUserBannerSaga(),
    rootdeviceSaga(),
    rootRefreshTokenSaga(),
    rootEcomUserSaga(),
    rootCitySaga(),
  ]);
}

export default rootSaga;
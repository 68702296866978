import { Avatar } from "@mui/material";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import NewLocationLogo from "../../../assets/LandingPage/NewLocationLogo.png";
import shoppingcart from "../../../assets/LandingPage/Shopping_Cart.png";
import myAccountIcon1 from "../../../assets/MyAccount/myAccountIcon1.png";
import myAccountIcon3 from "../../../assets/MyAccount/myAccountIcon3.png";
import myAccountIcon7 from "../../../assets/MyAccount/myCorporateProgram.png";
import myAccountIcon5 from "../../../assets/MyAccount/myPrescription.png";
import myAccountIcon8 from "../../../assets/MyAccount/mySubscription.png";
import nm7 from "../../../assets/MyAccount/nm7.png";
import myAccountIcon6 from "../../../assets/MyAccount/purchaseHistory.png";
import logo from "../../../assets/common/logoPrimaryNavbar.png";
import magnifying from "../../../assets/magniFying.png";
import SearchModal from "../../Organisms/Navbar/SearchModal";
import BookAppointment from "../../Pages/ServicesOffered/BookAppointment";
import NavigationTabs from "../NavigationTabs/NavigationTabs";
import "./NavbarWeb.css";
import SearchModalInput from "./SearchModalInput";

export default function NavbarWeb({
  storage,
  handleLogout,
  modalRef,
  handleModal,
  handleKeyPress,
  openSearchModal,
  handleCartClick,
  cartIsEmpty,
  badgeCount,
  id,
  open,
  anchorEl,
  handleClose,
  cityNames,
  handleInputModal,
  setopenModal,
  openmodal,
  inputRef,
  handleCityChange,
  cityId,
}) {
  const [openAppointment, setOpenAppointment] = useState(false);
  var nav = true;

  const getPopularPackage = useSelector(
    (state) => state.landing.getPopularPackage
  );
  const getPopularPlan = useSelector((state) => state.landing.getPopularPlan);
  const getPopularTest = useSelector((state) => state.landing.getPopularTest);
  const homeScreenProduct = useSelector(
    (state) => state.eCom.viewHomeScreenProduct
  );
  const elasticSearch = useSelector((state) => state.elasticSearch);

  function handleAppointment() {
    setOpenAppointment(true);
  }

  function closeAppointment(boolean) {
    setOpenAppointment(boolean);
  }

  return (
    <div className="NavbarMain">
      {openAppointment ? (
        <BookAppointment closeAppointment={closeAppointment} nav={nav} />
      ) : (
        ""
      )}
      <div className="NavbarMainUpper">
        <div className="NavbarMainUpperChild1">
          <a href="/" className="companyLogoNavbarLink">
            <img src={logo} alt="error" className="companyLogoNavbar" />
          </a>
        </div>
        <div className="NavbarMainUpperChild2">
          <NavigationTabs />
        </div>
        <div className="NavbarMainUpperChild3">
          <img className="" src={NewLocationLogo} alt="location icon" />
          <select className="cityNameSelect" onChange={handleCityChange}>
            {cityNames?.map((item, index) => {
              return (
                <option
                  value={item.id}
                  key={index}
                  selected={item.id.toString() === cityId?.toString()}
                >
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="NavbarMainUpperChild4">
          {storage.getStorage("token") ? (
            <div className=" navbarSideButtons">
              <div className="profile" id="navbarDropdown">
                <li className="nav_item dropdown" key={"avatar"}>
                  <Avatar
                    sx={{
                      height: "2vw",
                      width: "2vw",
                      marginTop: "0.7em",
                      marginBottom: "auto",
                    }}
                    className="pic"
                    src="/static/images/avatar/3.jpg"
                  />

                  <div
                    className="nav-link dropdown-toggle "
                    href="/"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      color: "#39466C",
                      gap: "1px",
                      marginTop: "0.5em",
                      cursor:"pointer"
                    }}
                  ></div>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a className="dropdown-item" href="/account">
                      {" "}
                      <img
                        alt="Account icon"
                        className="iconProfile"
                        src={myAccountIcon1}
                      ></img>
                      My Account
                    </a>
                    <a
                      className="dropdown-item"
                      href="/account/health-risk-assessment-report"
                    >
                      {" "}
                      <img
                        alt="Report icon"
                        className="iconProfile"
                        src={myAccountIcon3}
                      ></img>
                      My HRA Reports
                    </a>{" "}
                    <a className="dropdown-item" href="/MyDiagnosticReport">
                      {" "}
                      <img
                        alt="report Icon"
                        className="iconProfile"
                        src={myAccountIcon3}
                      ></img>
                      My Diagnostic Reports
                    </a>{" "}
                    <a className="dropdown-item" href="/account/prescription">
                      {" "}
                      <img
                        alt="Prescription icon"
                        className="iconProfile"
                        src={myAccountIcon5}
                      ></img>{" "}
                      My Prescriptions
                    </a>
                    <a className="dropdown-item" href="/order-history-packages">
                      {" "}
                      <img
                        alt="Purchase icon"
                        className="iconProfile"
                        src={myAccountIcon6}
                      ></img>
                      Purchase History
                    </a>{" "}
                    <a
                      className="dropdown-item"
                      href="/order-history-subscriptions"
                    >
                      {" "}
                      <img
                        alt="Subscription icon"
                        className="iconProfile"
                        src={myAccountIcon8}
                      ></img>
                      My Subscription
                    </a>{" "}
                    <a className="dropdown-item" href="/order-history-program">
                      {" "}
                      <img
                        alt="Program icon"
                        className="iconProfile"
                        src={myAccountIcon7}
                      ></img>
                      My Corporate Program
                    </a>{" "}
                    <div className="accountLine">
                      ________________________________________
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        className="dropdown-itemLogout"
                        onClick={() => handleLogout(false)}
                      >
                        <img
                          alt="Logout icon"
                          className="iconProfile"
                          src={nm7}
                        ></img>
                        <span style={{ color: "var(--globalColorHeader)" }}>
                          Logout
                        </span>
                      </div>
                      <div
                        className="dropdown-itemLogout"
                        onClick={() => handleLogout(true)}
                      >
                        <img
                          alt="Logout icon"
                          className="iconProfile"
                          src={nm7}
                        ></img>
                        <span style={{ color: "var(--globalColorHeader)" }}>
                          Logout From All Devices
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          ) : (
            <div className=" navbarSideButtons">
              <a href="/login">
                <button className="loginButton">Login</button>
              </a>
              <div>&nbsp;|&nbsp; </div>
              <a href="/register">
                <button className="registerButton">Sign Up</button>
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="NavbarMainLower">
        {/* <div className="NavbarMainLower1" > */}
        <div className="searchBar" ref={modalRef}>
          <input
            ref={inputRef}
            className="navSearchBar"
            type="search"
            placeholder="Search for Tests and Packages"
            aria-label="Search"
            onClick={handleModal}
            onKeyUp={(e) => e.key === "Enter" && handleKeyPress(e)}
            onChange={handleInputModal}
            id="search"
            autoComplete="off"
          />
          <div>
            {" "}
            <img src={magnifying} alt="search icon" />
          </div>
          {openmodal && (
            <SearchModal
              open={setopenModal}
              getPopularPackage={getPopularPackage}
              getPopularPlan={getPopularPlan}
              getPopularTest={getPopularTest}
              homeScreenProduct={homeScreenProduct}
            />
          )}
          {openSearchModal && (
            <SearchModalInput data={elasticSearch.data?.data.data.data} />
          )}
        </div>
        {/* </div> */}
        <div className="NavbarMainLower2">
          <button type="button" onClick={handleAppointment}>
            Book an Appointment
          </button>
        </div>
        <div className="NavbarMainLower3">
          <button className="shoppingBagButton" onClick={handleCartClick}>
            <Badge
              badgeContent={badgeCount === undefined ? 0 : badgeCount}
              color="primary"
            >
              <img
                alt="Cart icon"
                className="ShoppingCartImageSize"
                src={shoppingcart}
              ></img>
            </Badge>
          </button>

          {cartIsEmpty && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: 2 }} color=" #1D2334">
                Your Cart Is Empty!
              </Typography>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
}
